import React, { useState, useEffect } from 'react';
import './App.css';
import InfoSection from './components/InfoSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

function App() {
  const [isColorInverted, setColorInverted] = useState(false);
  const [pendingDots, setPendingDots] = useState(''); // State to hold the dots

  const handleColourInversion = () => {
    setColorInverted(!isColorInverted);
  }

  useEffect(() => {
    // Check initial color scheme preference
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      handleColourInversion();
    }

    // Listen for changes in color scheme preference
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = () => {
      setColorInverted(mediaQuery.matches);
    };
    mediaQuery.addEventListener('change', handleChange);
    
    // Cleanup
    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  const colours = ['white', '#111'];

  // Function to update the pending dots
  useEffect(() => {
    const intervalId = setInterval(() => {
      setPendingDots(prevDots => {
        switch (prevDots) {
          case '': return '.';
          case '.': return '..';
          case '..': return '...';
          default: return '';
        }
      });
    }, 300); // Change this value to adjust the speed of animation

    return () => clearInterval(intervalId); // Cleanup the interval
  }, []); // Run only once when the component mounts

  return (
    <div style={{ backgroundColor: isColorInverted ? colours[1] : colours[0], color: isColorInverted ? colours[0] : colours[1] }} className='App'>
      <div className="container">
        <h1 className="title">Aaron Dovey</h1>
        <InfoSection
          heading="BSc Mathematics"
          link="https://www.bristol.ac.uk"
          grade=" | First class honours"
          tags="Stats, AI, Financial, Machine Learning"
        />
        <InfoSection
          link="https://systemanova.vc"
          heading="Systema Nova VC"
          grade=""
          tags="Profit allocation solution"
        />
        <InfoSection
          link="https://www.cardiff.ac.uk"
          heading="MSc Data Science + Analytics"
          grade=" | Distinction"
          tags="Stats, AI, OR, HCI, Data Visualisation, Dissertation (hopefully in ML) "
        />
        <div className="links">
          <a className='link' href="https://www.linkedin.com/in/aarondovey" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} size="2x" style={{ color: `${isColorInverted ? colours[0] : colours[1]}`, transition: 'transform 0.3s ease-in-out' }} />
          </a>
          <svg className='darkmode' onClick={handleColourInversion} height="30" width="30" viewBox="0 0 100 100">
            <path
              style={{ fill: isColorInverted ? colours[0] : colours[1], transform: isColorInverted ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease-in-out', transformOrigin: '50%50%' }}
              fillRule="evenodd"
              clipRule="evenodd"
              d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100ZM36.6061 17.6642C40.8525 15.9053 45.4037 15 50 15V50L50 85C45.4037 85 40.8525 84.0947 36.6061 82.3358C32.3597 80.5769 28.5013 77.9988 25.2513 74.7487C22.0012 71.4987 19.4231 67.6403 17.6642 63.3939C15.9053 59.1475 15 54.5963 15 50C15 45.4037 15.9053 40.8525 17.6642 36.6061C19.4231 32.3597 22.0012 28.5013 25.2513 25.2513C28.5013 22.0012 32.3597 19.4231 36.6061 17.6642Z"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default App;
